import Splide from "@splidejs/splide";
import {Fancybox} from "@fancyapps/ui";

document.addEventListener('DOMContentLoaded', () => {
    let splide = new Splide('#splide1', {
        type: "loop",
        perPage: 1,
        pagination: false,
        autoplay: 500,
        gap: "0",
    });
    splide.mount();

    let splide2 = new Splide('#splide2', {
        type: "loop",
        perPage: 1,
        pagination: false,
        gap: '30px',
        breakpoints: {
            1250: {
                drag: false,
                swipe: false,
            }
        }
    });
    splide2.mount();

    let splide3 = new Splide('#splide3', {
        perPage: 4,
        perMove: 1,
        pagination: false,
        gap: '30px',
        breakpoints: {
            1250: {
                perPage: 3,
            },
            1150: {
                perPage: 2,
            },
            768: {
                perPage: 1,
            },
        }
    });
    splide3.mount();

    Fancybox.bind("[data-fancybox]", {
        Thumbs: {
            autoStart: false, // Отключить миниатюры, если не нужны
        },
    });

    const headerItems = document.querySelectorAll(".services__header_item");
    const tumbler = document.querySelector(".services__header_tumbler");
    const servicesBlocks = document.querySelectorAll(".services__items");

    const firstItem = headerItems[0];
    tumbler.style.transform = `translateX(${firstItem.offsetLeft}px)`;
    tumbler.style.width = `${firstItem.offsetWidth}px`;
    firstItem.classList.add("text-color-white");

    headerItems.forEach((item, index) => {
        item.addEventListener("click", () => {
            // Переключаем активный класс на заголовках
            headerItems.forEach(header => header.classList.remove("text-color-white"));
            item.classList.add("text-color-white");

            headerItems.forEach((item, index) => {
                item.classList.remove('services__header_item-active');
            });

            item.classList.add('services__header_item-active');

            // Перемещаем тумблер
            tumbler.style.transform = `translateX(${item.offsetLeft}px)`;
            tumbler.style.width = `${item.offsetWidth}px`;

            // Переключаем активные блоки услуг
            servicesBlocks.forEach(block => block.classList.add('hidden'));
            servicesBlocks[index].classList.remove('hidden');
        });
    });


    const servicesItems = document.querySelectorAll('.services__item');

    servicesItems.forEach((item) => {
        const text = item.querySelector('.services__item_text');
        const title = item.querySelector('.services__item_title');
        const icon = item.querySelector('.services__item_icon-container');

        if (text) {
            text.style.maxHeight = "0";
            text.style.display = "block";
            text.classList.add('animation-hidden-desk');
        }

        if (window.innerWidth <= 1249) {
            // Для мобильных: работаем по клику
            item.addEventListener('click', () => {
                if (text.style.maxHeight === "0px" || !text.style.maxHeight) {
                    text.style.maxHeight = text.scrollHeight + "px";
                    text.classList.remove('animation-hidden-desk');
                    title.classList.add('text-color-orange');
                    icon.classList.add('services__item_icon-container-active');
                } else {
                    text.style.maxHeight = "0";
                    text.classList.add('animation-hidden-desk');
                    title.classList.remove('text-color-orange');
                    icon.classList.remove('services__item_icon-container-active');
                }
            });
        } else {
            // Для десктопа: работаем по наведению
            item.addEventListener('mouseenter', () => {
                if (text) {
                    setTimeout(() => {
                        text.style.maxHeight = text.scrollHeight + "px";
                        text.classList.remove('animation-hidden-desk');
                        title.classList.add('text-color-orange');
                        icon.classList.add('services__item_icon-container-active');
                    }, 10);
                }
            });

            item.addEventListener('mouseleave', () => {
                if (text) {
                    setTimeout(() => {
                        text.style.maxHeight = "0";
                        text.classList.add('animation-hidden-desk');
                        title.classList.remove('text-color-orange');
                        icon.classList.remove('services__item_icon-container-active');
                    }, 10);
                }
            });
        }
    });

})